<template>
  <div class="logo-container" :class="routeClassName">
    <img src="/logos/Branding-black.svg" @click="router.push('/')" />
    <div class="language-selector">
      <span
        :class="languageSelected === 'es' ? 'body2-underline' : 'body2'"
        @click="$eventBus.$emit('setLocale', 'es'), (languageSelected = 'es')"
      >
        ES</span
      >
      <span class="body2">&nbsp;/&nbsp;</span>
      <span
        :class="languageSelected === 'en' ? 'body2-underline' : 'body2'"
        @click="$eventBus.$emit('setLocale', 'en'), (languageSelected = 'en')"
        >EN</span
      >
    </div>
  </div>
</template>

<script>
export default defineComponent({
  name: 'Header',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { width } = useBreakpoints()
    return {
      router,
      route,
      width
    }
  },
  data() {
    return {
      languageSelected: 'en'
    }
  },
  mounted() {
    if (localStorage?.getItem('languageSelected')) {
      this.languageSelected = localStorage?.getItem('languageSelected')
    } else {
      this.languageSelected =
        this.route.fullPath === '/'
          ? 'en'
          : this.route.fullPath === '/community'
          ? 'es'
          : navigator.language.split('-')[0] === 'es'
          ? 'es'
          : 'en'
    }
  },
  computed: {
    routeClassName() {
      return ['terms-and-conditions', 'privacy', 'cookies'].includes(
        this.route.name
      )
        ? 'footer'
        : ''
    }
  }
})
</script>

<style scoped lang="scss">
.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 139px;
  &.footer {
    height: 123px !important;
    background-color: var(--brand-white);
  }
  img {
    width: 150px;
    height: 75px;
    cursor: pointer;
  }
  @media (min-width: 1100px) {
    justify-content: center;
    margin-bottom: 30px;
  }
  @media (max-width: 1100px) {
    justify-content: center;
    margin-bottom: 24px;
  }
  .language-selector {
    display: flex;
    position: absolute;
    cursor: pointer;
    @media (min-width: 1100px) {
      align-items: center;
      right: 60px;
      height: 75px;
    }
    @media (max-width: 1100px) {
      height: 75px;
      right: 24px;
    }
    .body2 {
      color: var(--brand-mid-grey);
    }
    .body2-underline {
      color: var(--brand-off-black);
    }
  }
}
</style>