import { ElMessage } from 'element-plus'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      showSuccess: (message) => {
        ElMessage({
          showClose: true,
          message,
          type: 'success'
        })
      }
    }
  }
})
