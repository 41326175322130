export const appHead = {"meta":[{"hid":"description","name":"description","content":"Travel Redefined. Everything you need for limitless travel, in one place."},{"property":"og:description","content":"Travel Redefined. Everything you need for limitless travel, in one place."},{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"rel":"canonical","href":""},{"name":"robots","content":"index, follow"},{"property":"og:type","content":"website"},{"property":"og:title","content":"Paradise a La Carte"},{"property":"og:url","content":""}],"link":[{"rel":"icon","href":"/favicon-dark.svg"}],"style":[],"script":[{"src":"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"}],"noscript":[],"title":"Paradise a La Carte"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false