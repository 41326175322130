import { createI18n } from 'vue-i18n'

import es from '@/lang/es-ES.json'
import en from '@/lang/en-EN.json'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: navigator.language === 'es-ES' ? 'es' :
    navigator.language === 'en-EN' ? 'en' :
      'en',
  messages: {
    es,
    en
  }
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(i18n)
})
