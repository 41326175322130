<template>
  <div>
    <div class="footer-container" v-if="width > 1100">
      <div class="footer-title">
        <div class="footer-logo">
          <img
            :src="
              route.fullPath === '/'
                ? '/logos/PALC_Marque_White.svg'
                : '/logos/PALC_Marque_Black.svg'
            "
          />
          <div class="footer-logo-text">
            <span class="heading5">{{ $t('limitless_travel_one_place') }}</span>
          </div>
        </div>
        <div class="footer-about">
          <div class="footer-about-text" @click="router.push('/about-us')">
            <span class="body2">{{ $t('about_paradise_a_la_carte') }}</span>
          </div>
        </div>
      </div>
      <div class="footer-subtitle">
        <div class="footer-tos">
          <span class="body2">
            © {{ new Date().getFullYear() }}
            {{ $t('palc_all_rights_reserved') }}
          </span>
          <span
            class="body2"
            @click="router.push('/terms-and-conditions')"
          >
            {{ $t('terms_and_conditions') }}
          </span>
          <span class="body2" @click="router.push('/privacy')">
            {{ $t('privacy') }}
          </span>
          <span class="body2" @click="router.push('/cookies')">
            {{ $t('cookies_policies') }}
          </span>
        </div>
        <div class="footer-social">
          <a target="_blank" href="https://www.instagram.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/'
                  ? '16px/Social_Instagram_White'
                  : '16px/Social_Instagram'
              "
          /></a>
          <a target="_blank" href="https://www.facebook.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/'
                  ? '16px/Social_Facebook_White'
                  : '16px/Social_Facebook'
              "
          /></a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/paradise-a-la-carte"
            ><Icons
              :name="
                route.fullPath === '/'
                  ? '16px/Social_LinkedIn_White'
                  : '16px/Social_LinkedIn'
              "
          /></a>
        </div>
      </div>
    </div>
    <div class="footer-container" v-else>
      <div class="footer-title">
        <div class="footer-logo">
          <div class="footer-logo-text">
            <span class="heading5">{{ $t('limitless_travel_one_place') }}</span>
          </div>
          <img
            :src="
              route.fullPath === '/'
                ? '/logos/PALC_Marque_White.svg'
                : '/logos/PALC_Marque_Black.svg'
            "
          />
        </div>
        <div class="footer-about">
          <div class="footer-about-text" @click="router.push('/about-us')">
            <span class="body2">{{ $t('about_paradise_a_la_carte') }}</span>
          </div>
        </div>
      </div>
      <div class="footer-subtitle">
        <div class="footer-tos">
          <span
            class="body2"
            @click="router.push('/terms-and-conditions')"
          >
            {{ $t('terms_and_conditions') }}
          </span>
          <span class="body2" @click="router.push('/privacy')">
            {{ $t('privacy') }}
          </span>
          <span class="body2" @click="router.push('/cookies')">
            {{ $t('cookies_policies') }}
          </span>
        </div>
        <div class="footer-social">
          <a target="_blank" href="https://www.instagram.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/'
                  ? '16px/Social_Instagram_White'
                  : '16px/Social_Instagram'
              "
          /></a>
          <a target="_blank" href="https://www.facebook.com/paradisealacarte/"
            ><Icons
              :name="
                route.fullPath === '/'
                  ? '16px/Social_Facebook_White'
                  : '16px/Social_Facebook'
              "
          /></a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/paradise-a-la-carte"
            ><Icons
              :name="
                route.fullPath === '/'
                  ? '16px/Social_LinkedIn_White'
                  : '16px/Social_LinkedIn'
              "
          /></a>
        </div>
        <div class="footer-copyright">
          <span class="body2">
            © {{ new Date().getFullYear() }}
            {{ $t('palc_all_rights_reserved') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default defineComponent({
  name: 'Footer',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { width } = useBreakpoints()
    return {
      router,
      route,
      width
    }
  },
  data() {
    return {
      window
    }
  }
})
</script>

<style scoped lang="scss">
.footer-container {
  background: var(--brand-white);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  @media (max-width: 1100px) {
    height: 375px;
    padding: 40px 24px;
  }
  @media (min-width: 1100px) {
    height: 200px;
    padding: 40px 0px;
  }
  @media (min-width: 1100px) {
    .footer-title {
      margin: 0 auto;
      width: 85%;
      margin-bottom: 40px;
      .footer-logo {
        display: inline-block;
        .footer-logo-text {
          display: inline-block;
        }
        img {
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: -4px;
        }
      }
      .footer-about {
        float: right;
        display: inline-block;
        .footer-about-text {
          cursor: pointer;
          display: inline-block;
        }
      }
    }
    .footer-subtitle {
      margin: 0 auto;
      width: 85%;
      border-top: 1px solid var(--brand-mid-grey);
      padding-top: 24px;
      .footer-tos {
        display: inline-block;
        color: var(--brand-mid-grey);
        .body2 {
          margin-right: 32px;
          &:not(:first-of-type) {
            cursor: pointer;
          }
        }
      }
      .footer-social {
        float: right;
        display: inline-block;
        a {
          display: inline-block;
          &:not(:last-of-type) {
            margin-right: 24px;
          }
        }
      }
    }
  }
  @media (max-width: 1100px) {
    .footer-title {
      margin: 0 auto;
      width: 95%;
      margin-bottom: 24px;
      .footer-logo {
        margin-bottom: 24px;
        .footer-logo-text {
          width: calc(100% - 24px - 16px);
          display: inline-block;
          margin-right: 16px;
          .heading5 {
            font-size: 24px !important;
          }
        }
        img {
          display: inline-block;
          vertical-align: top;
        }
      }
      .footer-about {
        .footer-about-text {}
      }
    }
    .footer-subtitle {
      margin: 0 auto;
      width: 95%;
      border-top: 1px solid var(--brand-mid-grey);
      padding-top: 24px;
      .footer-tos {
        display: inline-block;
        color: var(--brand-mid-grey);
        .body2 {
          display: block;
          &:first-of-type {
            margin-bottom: 8px;
          }
          &:nth-child(2) {
            display: inline-block;
            margin-right: 8px;
          }
          &:nth-child(3) {
            display: inline-block;
            cursor: pointer;
          }
        }
      }
      .footer-social {
        float: right;
        display: inline-block;
        i,
        a {
          display: inline-block;
          &:not(:last-of-type) {
            margin-right: 24px;
          }
        }
      }
      .footer-copyright {
        color: var(--brand-mid-grey);
        margin-top: 24px;
      }
    }
  }
}
</style>