import { ElMessage } from 'element-plus'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      showError: (error) => {
        ElMessage({
          showClose: true,
          message: error,
          type: 'error',
          customClass: 'error-notification'
        })
      }
    }
  }
})
