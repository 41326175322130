import * as Sentry from '@sentry/browser'
export default defineNuxtPlugin((nuxtApp) => {
    const { public: { sentry } } = useRuntimeConfig()

    if (!sentry.dsn) {
        return {
            provide: {
                sentry: () => { }
            }
        }
    }

    Sentry.init({
        dsn: sentry.dsn,
        environment: sentry.environment,
        // Performance Monitoring
        tracesSampleRate: 0.1, // Change in production!
        // Set sampling rate for profiling - this is relative to tracesSampleRate
        profilesSampleRate: 0.1, // Change in production!
        ignoreErrors: [
            'Failed to fetch',
            'Non-Error promise rejection',
            '/_nuxt/builds/latest.json'
        ]
    })

    return {
        provide: {
            sentry: (error, tag, data = null) => {
                Sentry.configureScope((scope) => {
                    scope.setTag('transaction', tag)
                    if (error?.graphQLErrors?.length) {
                        error.graphQLErrors.forEach((error, index) => {
                            Sentry.addBreadcrumb({
                                category: `graphQLErrors${(index + 1)}`,
                                data: {
                                    graphQLErrors: error, debugMessage: error.debugMessage ? error.debugMessage : error.message
                                }
                            })
                        })
                    }
                    if (data) {
                        Sentry.addBreadcrumb({
                            category: 'data',
                            data
                        })
                    }
                    Sentry.captureException(new Error(error))
                })
            }
        }
    }
})