import { default as indexA6CkBJ9gD7Meta } from "/__w/landing-page/landing-page/pages/about-us/index.vue?macro=true";
import { default as forgot_45passwordjkAmvAWroOMeta } from "/__w/landing-page/landing-page/pages/auth/forgot-password.vue?macro=true";
import { default as register6FpZsYOtmdMeta } from "/__w/landing-page/landing-page/pages/auth/register.vue?macro=true";
import { default as reset_45passwordotBAwKl35NMeta } from "/__w/landing-page/landing-page/pages/auth/reset-password.vue?macro=true";
import { default as validate_45emailc3uObsadH8Meta } from "/__w/landing-page/landing-page/pages/auth/validate-email.vue?macro=true";
import { default as validate_45phone3ATy3zcvWrMeta } from "/__w/landing-page/landing-page/pages/auth/validate-phone.vue?macro=true";
import { default as indexzjPi8hX5lZMeta } from "/__w/landing-page/landing-page/pages/community/index.vue?macro=true";
import { default as index4lH3SXID4XMeta } from "/__w/landing-page/landing-page/pages/cookies/index.vue?macro=true";
import { default as indexmJIplrE2TVMeta } from "/__w/landing-page/landing-page/pages/home/index.vue?macro=true";
import { default as indexBG1OrYfAh6Meta } from "/__w/landing-page/landing-page/pages/index.vue?macro=true";
import { default as indexGCmocRIYBGMeta } from "/__w/landing-page/landing-page/pages/login/index.vue?macro=true";
import { default as indexH8bv50T0F0Meta } from "/__w/landing-page/landing-page/pages/privacy/index.vue?macro=true";
import { default as indexvaVHIaF6AeMeta } from "/__w/landing-page/landing-page/pages/suggestions/index.vue?macro=true";
import { default as indexFyFQhQCIMQMeta } from "/__w/landing-page/landing-page/pages/surveys/guide/index.vue?macro=true";
import { default as indexIztRmgCvLvMeta } from "/__w/landing-page/landing-page/pages/surveys/traveller/index.vue?macro=true";
import { default as indexEWLHdA3KyCMeta } from "/__w/landing-page/landing-page/pages/terms-and-conditions/index.vue?macro=true";
export default [
  {
    name: indexA6CkBJ9gD7Meta?.name ?? "about-us",
    path: indexA6CkBJ9gD7Meta?.path ?? "/about-us",
    meta: indexA6CkBJ9gD7Meta || {},
    alias: indexA6CkBJ9gD7Meta?.alias || [],
    redirect: indexA6CkBJ9gD7Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordjkAmvAWroOMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordjkAmvAWroOMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordjkAmvAWroOMeta || {},
    alias: forgot_45passwordjkAmvAWroOMeta?.alias || [],
    redirect: forgot_45passwordjkAmvAWroOMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: register6FpZsYOtmdMeta?.name ?? "auth-register",
    path: register6FpZsYOtmdMeta?.path ?? "/auth/register",
    meta: register6FpZsYOtmdMeta || {},
    alias: register6FpZsYOtmdMeta?.alias || [],
    redirect: register6FpZsYOtmdMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordotBAwKl35NMeta?.name ?? "auth-reset-password",
    path: reset_45passwordotBAwKl35NMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordotBAwKl35NMeta || {},
    alias: reset_45passwordotBAwKl35NMeta?.alias || [],
    redirect: reset_45passwordotBAwKl35NMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: validate_45emailc3uObsadH8Meta?.name ?? "auth-validate-email",
    path: validate_45emailc3uObsadH8Meta?.path ?? "/auth/validate-email",
    meta: validate_45emailc3uObsadH8Meta || {},
    alias: validate_45emailc3uObsadH8Meta?.alias || [],
    redirect: validate_45emailc3uObsadH8Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/validate-email.vue").then(m => m.default || m)
  },
  {
    name: validate_45phone3ATy3zcvWrMeta?.name ?? "auth-validate-phone",
    path: validate_45phone3ATy3zcvWrMeta?.path ?? "/auth/validate-phone",
    meta: validate_45phone3ATy3zcvWrMeta || {},
    alias: validate_45phone3ATy3zcvWrMeta?.alias || [],
    redirect: validate_45phone3ATy3zcvWrMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/auth/validate-phone.vue").then(m => m.default || m)
  },
  {
    name: indexzjPi8hX5lZMeta?.name ?? "community",
    path: indexzjPi8hX5lZMeta?.path ?? "/community",
    meta: indexzjPi8hX5lZMeta || {},
    alias: indexzjPi8hX5lZMeta?.alias || [],
    redirect: indexzjPi8hX5lZMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: index4lH3SXID4XMeta?.name ?? "cookies",
    path: index4lH3SXID4XMeta?.path ?? "/cookies",
    meta: index4lH3SXID4XMeta || {},
    alias: index4lH3SXID4XMeta?.alias || [],
    redirect: index4lH3SXID4XMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexmJIplrE2TVMeta?.name ?? "home",
    path: indexmJIplrE2TVMeta?.path ?? "/home",
    meta: indexmJIplrE2TVMeta || {},
    alias: indexmJIplrE2TVMeta?.alias || [],
    redirect: indexmJIplrE2TVMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: indexBG1OrYfAh6Meta?.name ?? "index",
    path: indexBG1OrYfAh6Meta?.path ?? "/",
    meta: indexBG1OrYfAh6Meta || {},
    alias: indexBG1OrYfAh6Meta?.alias || [],
    redirect: indexBG1OrYfAh6Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexGCmocRIYBGMeta?.name ?? "login",
    path: indexGCmocRIYBGMeta?.path ?? "/login",
    meta: indexGCmocRIYBGMeta || {},
    alias: indexGCmocRIYBGMeta?.alias || [],
    redirect: indexGCmocRIYBGMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexH8bv50T0F0Meta?.name ?? "privacy",
    path: indexH8bv50T0F0Meta?.path ?? "/privacy",
    meta: indexH8bv50T0F0Meta || {},
    alias: indexH8bv50T0F0Meta?.alias || [],
    redirect: indexH8bv50T0F0Meta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexvaVHIaF6AeMeta?.name ?? "suggestions",
    path: indexvaVHIaF6AeMeta?.path ?? "/suggestions",
    meta: indexvaVHIaF6AeMeta || {},
    alias: indexvaVHIaF6AeMeta?.alias || [],
    redirect: indexvaVHIaF6AeMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/suggestions/index.vue").then(m => m.default || m)
  },
  {
    name: indexFyFQhQCIMQMeta?.name ?? "surveys-guide",
    path: indexFyFQhQCIMQMeta?.path ?? "/surveys/guide",
    meta: indexFyFQhQCIMQMeta || {},
    alias: indexFyFQhQCIMQMeta?.alias || [],
    redirect: indexFyFQhQCIMQMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/surveys/guide/index.vue").then(m => m.default || m)
  },
  {
    name: indexIztRmgCvLvMeta?.name ?? "surveys-traveller",
    path: indexIztRmgCvLvMeta?.path ?? "/surveys/traveller",
    meta: indexIztRmgCvLvMeta || {},
    alias: indexIztRmgCvLvMeta?.alias || [],
    redirect: indexIztRmgCvLvMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/surveys/traveller/index.vue").then(m => m.default || m)
  },
  {
    name: indexEWLHdA3KyCMeta?.name ?? "terms-and-conditions",
    path: indexEWLHdA3KyCMeta?.path ?? "/terms-and-conditions",
    meta: indexEWLHdA3KyCMeta || {},
    alias: indexEWLHdA3KyCMeta?.alias || [],
    redirect: indexEWLHdA3KyCMeta?.redirect,
    component: () => import("/__w/landing-page/landing-page/pages/terms-and-conditions/index.vue").then(m => m.default || m)
  }
]